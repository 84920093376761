import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_CONFIG} from 'src/app/app.setings';
import {RelatorioV2Model} from 'src/app/shared/models/relatorios/relatorio-v2.model';
import {firstValueFrom} from 'rxjs';
import moment, {Moment} from 'moment';
import {DomSanitizer} from '@angular/platform-browser';

const TOKEN_RELATORIOS_KEY = 'token-relatorios';

@Injectable({
    providedIn: 'root',
})
export class RelatorioV2Service {
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {}

    public getAll(opts: {apenasAtivos?: boolean; editaveis?: boolean; estabelecs?: boolean} = {}) {
        let params: Record<string, any> = {};
        if (opts.apenasAtivos) params['ativos'] = true;
        if (opts.editaveis) params['editaveis'] = true;
        if (opts.estabelecs) params['estabelecs'] = true;
        return this.http.get<RelatorioV2Model[]>(`${API_CONFIG.url}relatorio-v2/`, {params});
    }

    resetToken() {
        localStorage.removeItem(TOKEN_RELATORIOS_KEY);
    }

    private getTokenFromStorage(): null | {token: string; iat: string} {
        let strStorage = localStorage.getItem(TOKEN_RELATORIOS_KEY);

        if (!strStorage) return null;

        try {
            let tokenObj = JSON.parse(strStorage);
            if (tokenObj.token && moment().diff(tokenObj.iat, 'h') <= 23) {
                return tokenObj;
            }
            localStorage.removeItem(TOKEN_RELATORIOS_KEY);
            return null;
        } catch (_) {
            return null;
        }
    }

    private setTokenStorage(token: string) {
        localStorage.setItem(TOKEN_RELATORIOS_KEY, JSON.stringify({token, iat: moment().toISOString()}));
    }

    public async getToken(): Promise<{token: string}> {
        let token: {token: string} = this.getTokenFromStorage();

        if (token) {
            return token;
        }
        token = await firstValueFrom(this.http.get<{token: string}>(`${API_CONFIG.url}relatorio-v2/auth`));

        this.setTokenStorage(token.token);

        return token;
    }

    public getById(id: string) {
        return this.http.get<RelatorioV2Model>(`${API_CONFIG.url}relatorio-v2/${id}`);
    }

    async save(relatorio: RelatorioV2Model, id?: string) {
        if (id) {
            return await firstValueFrom(this.http.put<{id: string}>(`${API_CONFIG.url}relatorio-v2/` + id, relatorio));
        } else {
            return await firstValueFrom(this.http.post<{id: string}>(`${API_CONFIG.url}relatorio-v2/`, relatorio));
        }
    }

    async delete(id: string) {
        return await firstValueFrom(this.http.delete(`${API_CONFIG.url}relatorio-v2/${id}`));
    }

    getUrlRelatorio(opts: {
        urlBase: string;
        codRelatorio: number;
        linkConnectorBi: string;
        dataIni: Date | Moment;
        dataFim: Date | Moment;
        estab: number;
        token: string;
    }) {
        let {urlBase, codRelatorio, linkConnectorBi, dataIni, dataFim, estab, token} = opts;
        let url = new URL(urlBase);

        dataIni = moment(dataIni);
        dataFim = moment(dataFim);

        let relatorioParametros = {
            'ds0.param_DATA_INI': dataIni.format('yyyy-MM-DD'),
            'ds0.param_DATA_FIM': dataFim.format('yyyy-MM-DD'),
            'ds0.param_ESTAB': estab,
            'ds0.token': token,
            'ds0.baseUrl': linkConnectorBi,
            'ds0.permissao_ESTAB': estab,
            'ds0.permissao_CODIGO_RELATORIO': codRelatorio,
        };

        url.searchParams.set('params', JSON.stringify(relatorioParametros));

        return this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
    }
}
